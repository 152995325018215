<template>
  <div class="view pa24" style="position: relative">
    <div class="d-flex" style="justify-content: space-between">
      <div>
        <el-input clearable class="w221 mr10" v-model="phone" placeholder="请输入手机号"/>
        <el-button type="primary" @click="fnQueryClick">查询</el-button>
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
        style="width: 100%;position: relative"
    >
      <template v-slot:table>
        <el-table-column prop="accountName" align="center" label="账号名称"/>
        <el-table-column align="center" label="微信头像" >
          <template slot-scope="scope">
            <el-image
                style="width: 80px; height: 40px"
                :src="scope.row.wxUrl"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="wxNickName" align="center" label="昵称"/>
        <el-table-column prop="phone" align="center" label="手机号"/>
        <el-table-column prop="createTime" align="center" label="创建时间"/>
        <el-table-column prop="endTime" align="center" label="到期时间"/>
        <el-table-column prop="remark" align="center" label="备注"/>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {queryListRecordPage} from "@/api/account";
import {Base64 as base64} from "js-base64";
export default {
  name: "recordTy",
  data() {
    return {
      phone: '',
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      centerDialogVisible: false,
      tableData: [],
      srcList:[]
    };
  },
  created() {
    this.queryPage()
  },
  components: {
    commonTable
  },
  methods: {
    /**@method 获取*/
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await queryListRecordPage(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        let a = []
        for (let i in list) {
          if (list[i].createTime) {
            list[i].createTime = this.renderTime(list[i].createTime);
          }
          if (list[i].endTime) {
            list[i].endTime = this.renderTime(list[i].endTime);
          }
          if (list[i].wxNickName) {
            list[i].wxNickName = base64.decode(list[i].wxNickName);
          }
          if (list[i].wxUrl) {
            a.push(list[i].wxUrl)
          }
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        phone: this.phone
      };
      this.queryPage(data);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        phone: this.phone
      };
      this.queryPage(data);
    },
    /**@method 查询 */
    fnQueryClick() {
      this.currentPage = 1;
      let data = {
        phone: this.phone
      };
      this.queryPage(data);
    },
  },
};
</script>

<style lang="scss" scoped>
.code_img {
  position: absolute;
  top: 30%;
  z-index: 10000;
  left: 35%;
  background: #FFFFFF;
}

/deep/ .el-table__body {
  position: relative;
}
</style>


